import React from 'react';

import { Layout } from '../../../../components/layouts';
import { SEO } from '../../../../components/common';
import {
  EgyptianCottonBeddingSetIntroSection,
  EgyptianCottonBeddingSetFeatureSection,
  EgyptianCottonBeddingSetUpsellHeroSection,
  EgyptianCottonBeddingSetAccordionSection,
  EgyptianCottonBeddingSetsUpsellGridSection,
  WoosaPromiseIconGridSection,
  MattressLateralSection,
  EgyptianCottonBeddingSetProductSection,
  EgyptianCottonBeddingSetPictureGridSection,
} from '../../../../components/sections';
import EgyptianCottonBeddingSetReviewHeroSection from '../../../../components/sections/egyptian-cotton-bedding-set-review-hero-section';
import { CountryCode } from '../../../../utils/constants';

const EgyptianCottonBeddingSetPage: React.FC = () => (
  <Layout countryCode={CountryCode.SG}>
    <SEO
      title="Shop 100% Egyptian Cotton Bedding Set"
      description="Indulge in pure luxury with our 100% Pure Egyptian Giza Cotton Bedsheets. Experience unmatched comfort and softness that gets even better with every wash. Crafted from long-staple cotton for exceptional durability, shop now to enhance your sleep experience."
      meta={[]}
    />
    <EgyptianCottonBeddingSetProductSection />
    <EgyptianCottonBeddingSetIntroSection />
    <EgyptianCottonBeddingSetPictureGridSection />
    <EgyptianCottonBeddingSetFeatureSection />
    <EgyptianCottonBeddingSetUpsellHeroSection />
    <EgyptianCottonBeddingSetAccordionSection />
    <EgyptianCottonBeddingSetReviewHeroSection />
    <EgyptianCottonBeddingSetsUpsellGridSection />
    <WoosaPromiseIconGridSection version="v2" />
    <MattressLateralSection version="v2" />
  </Layout>
);

export default EgyptianCottonBeddingSetPage;
